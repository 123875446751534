import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ChargeLocation  } from "../swt-ezio"
import { AsyncPaginate } from "react-select-async-paginate";
import { formatOptionLabel, scrollToSelected, Dropdown } from "../../utils/ezio-utils/SelectUtils"
import * as CS from "../../styles/ezio-styles/Controls-styles"
import { checkIfLocationSpecificPage } from "./HelperFunctions";

type LocationsDropdownProps = {
    chargeLocations: Map<string, ChargeLocation>,
    selectedChargeLocation: string
    onChange: Function,
    ALL_LOCATIONS_ID: string
}

export default function LocationsDropdown({ chargeLocations, selectedChargeLocation, onChange, ALL_LOCATIONS_ID }: LocationsDropdownProps) {

    const [locationOptions, setLocationOptions] = useState<Array<any>>([]);
    const [isOpen, setIsOpen] = useState(false);
    const route = useLocation();

    useEffect(() => {
        //the count is now unstable because of the charge worker pattern. let's order by address
        //just for consistency
        let arr: Array<any> = Array.from(chargeLocations.values());
        arr = arr.map((item: any) => {
            return ({ label: item.nickname ?? item.address, value: item.uuid, count: item.chargingVehiclesCount, isDisabled: false })
        });
        arr.sort((a,b)=>{if(a.label>b.label)return 1; if(a.label<b.label)return -1; return 0});

        //place "all locations" at the bottom of the list and disable it if we're on a detail page, otherwise, display it at the top
        const idx = arr.map((a)=>{return a.value}).indexOf(ALL_LOCATIONS_ID); // find the "all locations" object's index
        const allLocationsObj = arr.splice(idx, 1)[0]; // extract the object from the array
        if (checkIfLocationSpecificPage(route.pathname)) {
            allLocationsObj.isDisabled = true;
            arr.push(allLocationsObj); //place it at the end of the array
        } else arr.unshift(allLocationsObj); //place it at the beginning of the array
        setLocationOptions(arr);
    }, [chargeLocations, route, ALL_LOCATIONS_ID])

    //Unfortunately, adding pagination means stripping out the type checking specifically here. 
    //The data does come out of and into a variable that does check though, so this should be fine.
    const loadLocations = async (search: string, prevLocations: any) => {
        let filteredLocations = [];
        if (!search) {
            filteredLocations = locationOptions;
        } else {
            const searchLower = search.toLowerCase();
            filteredLocations = locationOptions.filter(({ label }) => label.toLowerCase().includes(searchLower));
        }
        const hasMore = filteredLocations.length > prevLocations.length + 10;
        const slicedLocations = filteredLocations.slice(
            prevLocations.length,
            prevLocations.length + 10
        );
        return {
            options: slicedLocations,
            hasMore
        }
    }

    function toggleOpen() {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        scrollToSelected();
    }, [isOpen])

    return (
        <Dropdown
            dropdownId="location-dropdown-testid"
            isOpen={isOpen}
            onClose={toggleOpen}
            target={
                <CS.LocationsMenuButton onClick={toggleOpen}>
                    <CS.LocationsMenuText>
                    {locationOptions.find((item: any) => item.value === selectedChargeLocation) ? locationOptions.find((item: any) => item.value === selectedChargeLocation).label : ""}
                    </CS.LocationsMenuText>
                    <CS.CustomDownChevron />
                </CS.LocationsMenuButton>
            }
        >
            <AsyncPaginate
                autoFocus
                backspaceRemovesValue={false}
                menuIsOpen
                controlShouldRenderValue={false}
                tabSelectsValue={false}
                loadOptions={
                    loadLocations
                }
                placeholder={"Search Location Names"}
                value={locationOptions.find((item: any) => item.value === selectedChargeLocation)}
                onChange={(event) => {
                    setIsOpen(false)
                    onChange(event.value)
                }}
                options={locationOptions}
                isSearchable={true}
                styles={
                    {
                        ...CS.SelectStyles,
                        container: (base: any) => {
                            return {
                                ...base,
                                width: "220px",
                                borderStyle: "none"
                            }
                        }
                    }
                }
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: null,

                }}
                formatOptionLabel={formatOptionLabel}
            />
        </Dropdown>
    )
}





