import styled from "styled-components/macro";
import * as colors from "./ColorScheme";
import { Link } from "react-router-dom";

export const DisplayLayout = styled.div`
display: flex;
flex-direction: column;
max-width: 1200px;
margin: 0 auto;
`

export const TableTitle = styled.h1`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 30px;
color: ${colors.ezio_dark_grey};
margin-bottom: 10px;
`

export const TableSubtitle = styled.span`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: ${colors.ezio_medium_grey};
margin-bottom: 20px;
`

export const LocationLink = styled(Link)`
color: inherit;
text-decoration: underline;
&:hover {
  color: ${colors.ezio_dark_green};
}
`