import React from 'react'
import Table from './Table'
import * as S from "../../styles/ezio-styles/VehiclesPage-styles"
import { vehicleIdDisplay, vehiclesPageTableColumns } from "./TableHelpers"
import { columnsToExcelHeaders, rowsToData } from "../../utils/ezio-utils/ExcelUtils"
import { useMemo, Suspense } from 'react'
import { filterByCategory, filterByGroups, filterByVehicleClass } from '../../utils/ezio-utils/DataUtils'

const ExcelDownloadButton = React.lazy(() => import("./ExcelDownloadButton"));

export default function VehiclesPage({chargeLocations, selectedChargeLocation, req, category, groups, vehicleClasses, beginDate, endDate, electrification, dbDisplayName, userSettings,forceNavigate, ALL_LOCATIONS_ID}) {
    const chargeLocation: any = chargeLocations.get(selectedChargeLocation);
    
    const formattedData = useMemo(() => {
        if(!chargeLocation) {return null}
        let data = JSON.parse(JSON.stringify(chargeLocation.vehicleResults))
        // Filter vehicle data based on controls
        data = filterByCategory(data, category);
        data = filterByVehicleClass(data, vehicleClasses);
        data = filterByGroups(data, groups);

        return data.map((vcl) => {
            vcl.asset_id = vehicleIdDisplay(vcl)
            return vcl;
        })
    },[chargeLocation, category, groups, vehicleClasses])

    const tableColumns = vehiclesPageTableColumns(forceNavigate);

    return (
        <S.DisplayLayout>
            <S.TableTitle>
                All Vehicles
            </S.TableTitle>
            <S.TableSubtitle>
                This table lists all the vehicles {selectedChargeLocation === ALL_LOCATIONS_ID ? `in your fleet` : `projected to charge at location ${chargeLocation.nickname ?? chargeLocation.address}`} that match the selected filters.
            </S.TableSubtitle>
            <Table
                columns={tableColumns}
                data={formattedData}
                type={"details"}
                hoverHighlight={false}
                defaultPrimarySort={{columnId:"assetId",sortDesc:false}}
            />
            <div>
                <Suspense fallback = {<div></div>}>
                    <ExcelDownloadButton
                        csvType={'vehicles'}
                        beginDate={beginDate}
                        endDate={endDate}
                        category={category}
                        groups={groups}
                        vehicleClasses={vehicleClasses}
                        electrification={electrification}
                        location={chargeLocation}
                        db={req.dbName}
                        columns={columnsToExcelHeaders(tableColumns)}
                        data={rowsToData(tableColumns, formattedData, (a: any, b: any) => a.assetId > b.assetId ? 1 : -1)}
                        dbDisplayName={dbDisplayName}
                        userSettings={userSettings}
                    />
                </Suspense>
            </div>
        </S.DisplayLayout>
  )
}
