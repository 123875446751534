import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import "./component-css/core-stylesheets/dashboard-core.css";
import Header from "./components/core-components/dashboard-components/swt-header";
import NavMenu from "./components/core-components/dashboard-components/swt-nav-menu";
import CredentialInput from "./components/core-components/dashboard-components/admin-components/swt-credential-input";
import PasswordChange from "./components/core-components/dashboard-components/admin-components/password-change";
import PasswordReset from "./components/core-components/dashboard-components/admin-components/swt-password-reset";
import PasswordTokenChange from "./components/core-components/dashboard-components/admin-components/password-token-change";
import PasswordResetMsg from "./components/core-components/dashboard-components/admin-components/swt-password-reset-msg";
import UserManagementMenu from "./components/core-components/dashboard-components/UserManagementMenu";
import CDRManagementMain from "./components/core-components/dashboard-components/admin-components/CDRManagementMain";
import LandingComponent from "./components/core-components/dashboard-components/swt-landing";
import AdminMain from "./components/core-components/dashboard-components/AdminMain";
import ComponentRegistry from "./component-registry";
import OutsideClickDetector from "./components/core-components/utility-components/OutsideAlerter";
import HandleExpiredToken from "./components/core-components/dashboard-components/admin-components/swt-handle-expired-token";
import UserSettings from "./components/core-components/dashboard-components/admin-components/UserSettings";
import { processApiResponse } from "./components/core-components/utility-components/ConformUnits";
import ContactCta from "./components/core-components/dashboard-components/ContactCta";
import User, { Roles, createUser } from "./components/core-components/utility-components/UserUtils";
import DevToolsMain from "./components/swt-dashboard-core";
import { calculateProjectedSavings } from "./components/core-components/dashboard-components/UtilityFunctions";
import LocationEditTool from "./components/core-components/dashboard-components/LocationEditTool";

const MIN_CDR_MANAGEMENT_VERSION = 2;
const SERVER_ERRORED_MESSAGE = "There was a server error during your request."

class App extends React.Component {

  constructor(props) {
    super(props);
    let u = new User();
    //Our API is now versioned with a url 
    // schema like: https://api.sawatchlabs/<version>/
    // at present we pull this from hardcoded strings in the secrets file,
    // but in the future we need to refactor this to come from the CDR, so dashboard
    // versions and api versions are not completely coupled.
    this.secrets = require("./settings");
    this.basename = props.basename;
    this.ComponentRegistry = new ComponentRegistry();
    let passwordRules = this.secrets.passwordRules;

    this.state = {
      devState: this.secrets.devState,
      version: props.version,
      versioningEnabled: true,
      focusedComponent: "loading",
      showUserManagementMenu: false,
      showNavMenu: false,
      showDatabaseDropDown: false,
      passwordRules: passwordRules,
      forceReset: false,
      db: null,
      productSettings: [],
      dbSettings: [],
      databases: [],
      products: [],
      credentials: {},
      activeLink: "landing",
      token: null,
      apiURL: this.secrets.productionApi,
      user: u,
      posthog: props.posthog,
      vehiclesAnalyzed: null,
      tcoSavings: null,
      cobrandingLogoUrl: null,
      cobrandingLogoUrlReceived: false,
      databaseUiUpdating: true
    };
    if (this.secrets.devState !== "production")
      this.state.apiURL = this.secrets.devApi;
  }

  componentDidMount = () => {
    let href = window.location.href.toLowerCase();
    if (href.includes("validatetoken")) {
      let query = this.getUrlQuery(href);
      if (query.token) this.validateResetToken(query.token);
      return;
    }
    this.authenticate();
  };

  componentDidUpdate = () => {
    // Sets previous database to current if back button is clicked and the location is back to '/landing'
    // NOTE Updates db state but display (incl db dropdown) doesn't update until a product is clicked - fix this
    onpopstate = () => {
      const previousLoc = this.props.history.location;
      if (
        previousLoc.state !== undefined &&
        previousLoc.state !== null &&
        previousLoc.state.db !== undefined &&
        previousLoc.state.db !== this.state.db &&
        previousLoc.pathname === '/landing'
      ) {
        this.handleDBChange({ database: previousLoc.state.db });
      }
    };
  };
  
  getDbDisplayName = () => {
    let dbName = this.state.databases.filter((d) => d.db === this.state.db)
    if (dbName.length === 0) return;
    if (dbName.length > 0) return dbName[0].db_name;
  }

  getCredentials = () => {
    this.setState({ focusedComponent: "auth" });
    this.props.history.push("/");
  };

  passCredentials = (data) => {
    let url = this.secrets.authenticationApi + "pass/";
    this.setState({ credentials: data });
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.authenticated === true) {
          //reject non-dev users in dev enviroments
          if (this.state.devState !== "production") {
            if (data.developer !== true) {
              window.alert(
                "Our Customer URL has Changed. Redirecting You To: dashboard.sawatchlabs.com"
              );
            window.location.replace("https://dashboard.sawatchlabs.com");
            return;
          }
        }
          this.login(data);
        } else {
          window.alert(
            "Credentials Error",
            "Credentials supplied are not valid.",
            "warning"
          );
        }
      });
  };

  authenticate = () => {
    let url = this.secrets.authenticationApi + "user_auth";
    fetch(`${url}`)
      .then((resp) => resp.json())
      .then((data) => {
        if (data.authenticated === true) {
          this.login(data);
          return;
        } else {
          this.props.history.push("/authenticate");
        }
      }).catch(err => console.error(err));
  };

  activeDbFromCookie = () => {
    const cookies = document.cookie.split(";");
    const dbCookie = cookies.find((c) => c.includes("activeDb"));
    if(dbCookie){
      const db = dbCookie.split("=");
      if(db[1])return db[1].trim();
    }
    return null;
  }

  navigateToVersion = (nextVersion) => {
    let urlComponents = window.location.href.split("/");
    let correctVersion = `v${nextVersion[0] ? nextVersion[0] : this.secrets.dashboardVersion[0]}`;
    
    //allows superadmins to disable versioning
    if(!this.state.versioningEnabled)correctVersion = `v${this.state.version[0]}`;
  
    if(urlComponents.indexOf(correctVersion) < 0){
      if(this.secrets.dashboardURL.includes("localhost"))return;
      window.location.href = `${this.secrets.dashboardURL}${correctVersion}`;
    }
  }

  setUnitLabels = (userSettings) => {
    // **km/mile labels
    userSettings.distance_labels = userSettings.use_kms ?
    {shortSingular: 'km', shortPlural: 'kms', longSingular: 'Kilometer', longPlural: 'Kilometers'}
    :
    {shortSingular: 'mi', shortPlural: "mi", longSingular: 'Mile', longPlural: 'Miles'}
    // **kg/lbs labels
    userSettings.weight_labels = userSettings.use_kgs ?
    {shortSingular: 'kg', shortPlural: 'kgs', longSingular: 'Kilogram', longPlural: 'Kilograms'}
    :
    {shortSingular: 'lb', shortPlural: 'lbs', longSingular: 'Pound', longPlural: 'Pounds'}
    // **gals/liter labels
    userSettings.liquid_volume_labels = userSettings.use_liters ?
    {shortSingular: 'l', shortPlural: 'l', longSingular: 'Liter', longPlural: 'Liters'}
    :
    {shortSingular: 'gal', shortPlural: 'gals', longSingular: 'Gallon', longPlural: 'Gallons'}
    // **tons/metric tons
    userSettings.ton_labels = userSettings.use_kgs ?
    {shortSingular: 'mt', shortPlural: 'mt', longSingular: 'Metric Ton', longPlural: 'Metric Tons'}
    :
    {shortSingular: 'T', shortPlural: 'T', longSingular: 'Ton', longPlural: 'Tons'}
    return userSettings
  }

  login = (data) => {
    const u = createUser(data);
    let forceReset = false;
    if (typeof data.force_reset !== "undefined") forceReset = data.force_reset;
    if (
      typeof data.user !== "undefined" &&
      typeof data.user.force_reset !== "undefined"
    )
    forceReset = data.user.force_reset;
    
    //what is the right database to load
    const cdb = this.activeDbFromCookie();
    let activeDb = cdb ? cdb : data.primary_db;

    //databases can't be named anything falsey
    data.databases.forEach((d) => {if(!d.db_name)d.db_name = d.db});

    //does this person have authorization to load that database?
    const databaseExists = data.databases.find((d) => d.db === cdb)
    if(!databaseExists)activeDb = data.primary_db;

    //save the right db
    this.handleCookieUpdate(activeDb);

    //am i on the right dashboard version? and if not navigate to it
    if(databaseExists){
      const version = databaseExists.dashboard_version.split(".");
      this.navigateToVersion(version);
    }

    // get settings for user
    let settingsUrl = this.state.apiURL + `getUserSettings?email=${u.email}`;
    fetch(settingsUrl, {
      headers: { Authorization: `Bearer ${u.token}` },
      }).then(res => res.json())
      .then((data) => {
        if (data.status === "error") {
          alert(SERVER_ERRORED_MESSAGE);
        }
        else {
          // add user settings to user object
          u.userSettings = {...u.userSettings, ...data['data'][0]};
          // Convert hex unicode currency symbol to be displayable as symbol in product
          u.userSettings.currency_symbol = String.fromCodePoint(u.userSettings.currency_symbol);
          //Attach display unit objects
          u.userSettings = this.setUnitLabels(u.userSettings)
        }
      })
      .catch((err) => {console.error(err)});
    if(this.state.posthog) { // If traffic analysis is enabled, identify user and record active database
      this.state.posthog.identify(
        u.email,
        { email: u.email } // optional: set additional user properties
      );
      this.state.posthog.capture('login', { // Capture login event and 
        email: u.email,
        db_name: activeDb
      });
    }
    this.setState({
      forceReset: forceReset,
      db: activeDb,
      showNavMenu: true,
      user: u,
    });
    this.sortDatabases(data.databases);
    this.getProductInfo(activeDb);
    this.getDbSettings(activeDb);
    this.getDbVehicles(activeDb);
    this.getDbCobrandingLogo(activeDb);
    this.getDbUiUpdateStatus(activeDb);
    if (!forceReset)
      this.props.history.push("/landing", { db: activeDb });
    else this.props.history.push("/pw-change");
  };

  logout = () => {
    let url = this.secrets.authenticationApi + "logout/";
    fetch(url, {
      method: "GET"
    }).then(res => res.json())
      .then((data) => {
        this.props.history.push("/");
        window.location.reload();
      })
      .catch((data) => {
        this.props.history.push("/");
        window.location.reload();
      });
  };

  resetPassword = () => {
    this.setState({ focusedComponent: "pw-reset", authenticated: false });
    this.props.history.push("/pw-reset");
  };

  toggleVersioning = () => {
    if(this.state.versioningEnabled === true){
      const nextVersion = this.secrets.dashboardVersion.split(".");
      this.navigateToVersion(nextVersion);
    }
    else{
      const nextDatabase = this.state.databases.find((d) => d.db === this.state.db);
      const nextVersion = nextDatabase.dashboard_version.split(".");
      this.navigateToVersion(nextVersion);
    }
    this.setState({ versioningEnabled: !this.state.versioningEnabled });

  }

  changePassword = () => {
    if (!this.state.user.authenticated) return;
    this.setState({ focusedComponent: "pw-change", showUserManagementMenu: false });
    this.props.history.push("/pw-change");
  };

  getDbSettings = (db) => {
     // get settings for db
     fetch(`${this.state.apiURL}getSettings?dbName=${db}`, {
       headers: { Authorization: `Bearer ${this.state.user.token}` },
       }).then(res => res.json())
       .then((data) => {
          if (data.status === "error") {
            alert(SERVER_ERRORED_MESSAGE);
          }
          else {
            if(data && data['data'] && data['data'][0]){
              let settings = data['data'][0];
              fetch(`${this.state.apiURL}getCurrencies`, {
                headers: { Authorization: `Bearer ${this.state.user.token}` },
              })
              .then(res => res.json())
              .then((data) => {
                if (data.status === "error") {
                  alert(SERVER_ERRORED_MESSAGE);
                }
                else {
                  // update user object to include db currency
                  let u = this.state.user;
                  if(!u.userSettings)u.userSettings = {}
                  u.userSettings.db_currency = settings.currency;
    
                  let cur = data.data.filter(d => d.currency_code === settings.currency)[0];
                  u.userSettings.to_usd = cur.to_usd;
                  u.userSettings.to_eur = cur.to_eur;
                  settings.currency_symbol = String.fromCodePoint(settings.currency_symbol);
                  let productSettings = {...settings} // create a copy of settings
                  productSettings = processApiResponse(u.userSettings, productSettings, true);
                  // Don't want to process currency for fleet settings in settings cog context
                  settings = processApiResponse(u.userSettings, settings, false);
                  // if(this.state.user.userSettings.email === 'default'){
                  //   // if default user (no currency) do we want to update
                  //   // w/ db currency or leave blank?
                  //   // update currency settings to match current db
                  //   u.userSettings.currency = settings.currency
                  // }
                  // update settings and user
                  this.setState({ productSettings: productSettings, dbSettings: settings, user: u });
                }
              })
            }
          }
      })
      .catch((err) => {console.error(err)});
  }

  getDbVehicles = (db) => {
    // get TCO for landing page stat bubble
    const vehiclesResultsUrl = `${this.state.apiURL}getVehicleResults?dbName=${db}&groupId=swt-vehicles&all_categories=true`;
    fetch(vehiclesResultsUrl, { 
      headers: { Authorization: `Bearer ${this.state.user.token}` },
      }).then(res => res.json())
      .then(data => {
        const vehicles = data['data'];
        this.setState({
          tcoSavings: calculateProjectedSavings(vehicles)
        })
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          tcoSavings: -1 // ensure that the landing page does not remain on a loading screen forever - it expects a value other than null
        })
      });

    // get vehicle count for landing page stat bubble
    const groupMembersUrl = `${this.state.apiURL}getGroupMembers?dbName=${db}&group_id=swt-vehicles&all_categories=true`;
    fetch(groupMembersUrl, { 
      headers: { Authorization: `Bearer ${this.state.user.token}` },
      }).then(res => res.json())
      .then(data => {
        const vehicles = data['data'];
        this.setState({
          vehiclesAnalyzed: vehicles.filter(v=> v.selected).length
        })
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          vehiclesAnalyzed: -1 // ensure that the landing page does not remain on a loading screen forever - it expects a value other than null
        })
      });
  }

  getDbUiUpdateStatus = (db) => {
    fetch(`${this.state.apiURL}getDatabaseUiUpdateStatus?dbName=${db}`, {
      headers: { Authorization: `Bearer ${this.state.user.token}` },
    })
      .then((res) => res.json())
      .then(data => {
        this.setState({
          dbUiUpdating: data.ui_updating
        });
      })
      .catch((err) => console.error("An error occurred while getting the database's UI update status.", err));
  }

  getDbCobrandingLogo = (db) => {
    const vehiclesResultsUrl = `${this.state.apiURL}getDbCobrandingLogo?dbName=${db}`;
    fetch(vehiclesResultsUrl, { 
      headers: { Authorization: `Bearer ${this.state.user.token}` },
      }).then(res => res.json())
      .then(data => {
        const url = data.data[0].cobranding_logo_url;
        this.setState({
          cobrandingLogoUrl: url,
          cobrandingLogoUrlReceived: true
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  changeDbCobrandingLogo = (db, image) => {
    if (!image) { //if no image is provided, reset the existing image URL
      fetch(`${this.state.apiURL}clearDbCobrandingLogo?dbName=${db}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.state.user.token}`,
        }
      })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "error")
          alert(SERVER_ERRORED_MESSAGE);
        else
          this.getDbCobrandingLogo(db);
      });
    } else { // otherwise, pass the image to create the new URL
      let formData = new FormData();
      formData.append("image", image);
      formData.append("dbName", db);
      fetch(`${this.state.apiURL}updateDbCobrandingLogo`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.state.user.token}`,
          },
          body: formData
      })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "error")
          alert(SERVER_ERRORED_MESSAGE);
        else
          this.getDbCobrandingLogo(db);
      });
    } 
  }

  getProductInfo = (db) => {
    Promise.all([
      fetch(`${this.state.apiURL}productInfo?database=${db}`, {
        headers: { Authorization: `Bearer ${this.state.user.token}` },
      }),
      fetch(`${this.state.apiURL}productsForDatabase?database=${db}`, {
        headers: { Authorization: `Bearer ${this.state.user.token}` },
      }),
    ])
      .then(([res1, res2]) => {
        return Promise.all([res1.json(), res2.json()]);
      })
      .then(([data1, data2]) => {
        var purchasedProducts = [];
        if (data2.status === "error") return alert(SERVER_ERRORED_MESSAGE);
        if (data2 && data2.data) {
          purchasedProducts = data2.data.map((d) => {
            return d.product;
          });
        }
        if (data1.status === "error") return alert(SERVER_ERRORED_MESSAGE);
        if (data1 && data1.data && data1.data.length > 0) {
          return data1.data.map((p) => {
            var purchased = purchasedProducts.includes(p.product)
              ? true
              : false;
            p["purchased"] = purchased;
            return p;
          });
        }
      })
      .then((allProducts) => {
        this.setState({ products: allProducts });
      });
  };

  addDatabase = (database) => {
    let databases = this.state.databases;
    // add field for db_name (display name) if/when use this fcnt
    databases.push({ db: database, db_name: database });
    this.setState({ databases: databases });
  };

  updateDatabases = (dbs) => {
    let updatedDbList = dbs.map((d) => {
      return {db: d.id, db_name: d.display_name, dashboard_version: d.dashboard_version}
    })
    if(updatedDbList.length > 0) {
      this.sortDatabases(updatedDbList)
    }
  }

  sortDatabases = (dbs) => {
    const dbsArr = dbs.sort((a,b) => {
      let dbA = a.db_name ? a.db_name : a.db;
      let dbB = b.db_name ? b.db_name : b.db;
      return dbA.localeCompare(dbB)}).map((d) => {
      return d.db;
    });
    let idx = dbsArr.indexOf(this.state.user.primaryDb);
    if (idx > -1) {
      // cut primaryDb and insert at idx 0
      let hdb = dbs.splice(idx, 1);
      dbs.unshift(hdb[0]);
    }
    this.setState({ databases: dbs });
    if (idx > -1) this.showDatabaseDropDown();
  };

  showNavMenu = () => {
    if (this.state.user.authenticated) {
      if (this.state.forceReset)
        this.setState({ focusedComponent: "pw-change" });
      else this.setState({ showNavMenu: true, focusedComponent: "landing" });
    }
  };

  showUserManagementMenu = () => {
    if (this.state.user.authenticated)
      this.setState({ showUserManagementMenu: !this.state.showUserManagementMenu });
  };

  showUserSettings = () => {
    this.setState({ focusedComponent: 'user-settings'})
    this.props.history.push('/user-settings')
    this.showUserManagementMenu()
  }

  handleResetPassword = (email) => {
    let url = this.secrets.authenticationApi + "passwordReset/" + email;
    fetch(url, {
      method: "get",
    })
      .then((data) => {
        this.setState({ focusedComponent: "pw-reset-msg" });
        this.props.history.push("pw-reset-msg");
      })
      .catch((err) => {
        console.error("reset failure:", err);
      });
  };

  createNewUserPassword = (email) => {
    let url = this.state.apiURL + "createUserPassword/" + email;
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.user.token}`,
      },
    });
  };

  handlePasswordChange = (password, newPassword) => {
    let data = {
      username: this.state.user.email,
      password: password,
      newPassword: newPassword,
    };
    let url = this.state.apiURL + "changePasswordRequest";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.user.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => console.warn("password changed", res));

    this.setState({ focusedComponent: "landing", forceReset: false });
    this.props.history.push("/landing");
  };

  handleTokenPasswordChange = (newPassword) => {
    let data = { token: this.state.token, newPassword: newPassword };
    let url = this.secrets.authenticationApi + "changePasswordWithToken/";

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => {
        this.setState({ focusedComponent: "auth" });
        console.warn("password update");
        this.props.history.push("/authenticate");
      });
  };

  handleNavItemSelect = (item) => {
    const prevDB =
      this.props.history.location.state && this.props.history.location.state.db
        ? this.props.history.location.state.db
        : this.state.db;
    if (item === "evse-map") item = "ezio";
    if (item ==="ezev") item = "ezev-td"; // NOTE this is a temporary cludge to hook the ezev td icon up to the product, until the item is renamed in the cdr products table
    this.setState({ focusedComponent: item });
    this.props.history.push({
      pathname: `/${item}`,
      state: { db: prevDB },
    });
  };

  showDatabaseDropDown = () => {
    if (this.state.user.authenticated && this.state.databases.length > 1) {
      this.setState({ showDatabaseDropDown: true });
    } else this.setState({ showDatabaseDropDown: false });
  };

  handleCookieUpdate = (nextDb) => {
    const currentCookies = document.cookie.split(";")
    let nextCookies = `activeDb=${nextDb};path=/;`;
    currentCookies.forEach((c) => {
      if(c.includes("activeDb"))return;
      if(c.includes("path"))return;
      else nextCookies = `${nextCookies}${c};`;
    });
    document.cookie = nextCookies;
  }

  handleDBChange = (db) => {
    const nextDatabase = this.state.databases.find((d) => d.db === db.database)
    let nextVersion = nextDatabase.dashboard_version.split(".");

    this.handleCookieUpdate(nextDatabase.db);
    
    //allow dev dashboards to disable versioning
    if(!this.state.versioningEnabled)nextVersion = this.state.version;

    if(this.state.version[0] !== nextVersion[0]){
      this.navigateToVersion(nextVersion);
      if(!this.secrets.dashboardURL.includes("localhost"))return;// flow thru to updates if on local
    }
    if(this.state.posthog) { // If traffic analysis enabled, record database change event
      this.state.posthog.capture('database_change', {
        db_name: nextDatabase.db
      })
    }
    this.props.history.push(`/landing`, { db: nextDatabase.db });
    this.setState({ db: nextDatabase.db, products: [], vehiclesAnalyzed: null, tcoSavings: null});
    this.getProductInfo(nextDatabase.db);
    // get settings and cobranding logo for new database
    this.getDbSettings(nextDatabase.db);
    this.getDbVehicles(nextDatabase.db);
    this.getDbCobrandingLogo(nextDatabase.db);
    this.getDbUiUpdateStatus(nextDatabase.db);
  };

  handleChangePasswordCancel = () => {
    this.setState({ focusedComponent: "/landing" });
    this.props.history.push("/landing");
  };

  handleTokenChangePasswordCancel = () => {
    this.setState({ focusedComponent: "auth" });
    this.props.history.push("/");
  };

  validateResetToken = (token) => {
    let url = this.secrets.authenticationApi + `validateToken/${token}`;
    fetch(url, { method: "get" })
      .then(res => res.json())
      .then((data) => {
        let d = data.data;
        if (typeof d !== "undefined" && d.includes("success")) {
          this.setState({ focusedComponent: "token-change", token: token });
          this.props.history.push("/token-change");
          return;
        }
        if (typeof d !== "undefined" && d.includes("expired")) {
          this.setState({ focusedComponent: "handle-expired-token" });
          this.props.history.push("/handle-expired-token");
        } else this.authenticate();
      })
      .catch(err => console.error(err));
  };

  getUrlQuery = (url) => {
    let params = url.split("?");
    if (url.includes("&")) params = url.split("?")[1].split("&");
    let obj = {};
    for (var p in params) {
      obj[params[p].split("=")[0]] = params[p].split("=")[1];
    }
    return obj;
  };

  render() {
    const { authenticated } = this.state.user;
    if (this.props.location.pathname.includes("validatetoken")) {
      let query = this.getUrlQuery(window.location.href);
      if (query.token) this.validateResetToken(query.token);
      return (
        <PasswordTokenChange
          passwordRules={this.secrets.passwordRules}
          cancelRequest={this.handleTokenChangePasswordCancel}
          handleRequest={this.handleTokenPasswordChange}
        />
      );
    }
    const updateUserSettings = () => {
      let u = this.state.user;
      let url = this.state.apiURL + `getUserSettings?email=${u.email}`;
      fetch(url, {
        headers: { Authorization: `Bearer ${u.token}` },
        }).then(res => res.json())
        .then((data) => {
          if (data.status === "error") {
            alert(SERVER_ERRORED_MESSAGE);
          }
          else {
            // add user settings to user object
            u.userSettings = data['data'][0];
            // Convert hex unicode currency symbol to be displayable as symbol in product
            u.userSettings.currency_symbol = String.fromCodePoint(u.userSettings.currency_symbol);
            //Attach display unit objects
            u.userSettings = this.setUnitLabels(u.userSettings)
            this.getDbSettings(this.state.db);
          }
        })
        .catch((err) => {console.error(err)});      
      
      this.props.history.push("/landing")
    }

    const updateDbSettings = () => {
      this.getDbSettings(this.state.db)
    }

    return (
      <div className="swt-app">
        <Header
          key={this.props.location.pathname}
          user={this.state.user}
          logout={this.logout}
          login={this.getCredentials}
          showUserManagementMenu={this.showUserManagementMenu}
          showDatabaseDropDown={this.state.showDatabaseDropDown}
          handleDBChange={this.handleDBChange}
          db={this.state.db}
          databases={this.state.databases}
          onSelect={this.handleNavItemSelect}
          version={this.state.version}
          cobrandingLogoUrl={this.state.cobrandingLogoUrl}
          cobrandingLogoUrlReceived={this.state.cobrandingLogoUrlReceived}
        />
        {this.state.showNavMenu && this.state.user.userSettings && this.state.user.userSettings.db_currency &&
          // We should refactor the above later and fix the root cause of the user settings race condition
          // Probably should promise chain the calls to getSettings and getUserSettings so we can gurantee they run sequentially
          <NavMenu
            products={this.state.products}
            onSelect={this.handleNavItemSelect}
            cdrManagementEnabled={parseInt(this.state.version[0]) < MIN_CDR_MANAGEMENT_VERSION ? false : true}
            user={this.state.user}
          />
        }
        <OutsideClickDetector outsideClickCallback={() => {if(this.state.showUserManagementMenu)this.setState({ showUserManagementMenu: false })}}>
          {this.state.showUserManagementMenu && (
            <UserManagementMenu
              logout={this.logout}
              user={this.state.user}
              changePassword={this.changePassword}
              showUserManagementMenu={this.showUserManagementMenu}
              toggleVersioning={this.toggleVersioning}
              versioningEnabled={this.state.versioningEnabled}
              showUserSettings={this.showUserSettings}
            />
          )}
        </OutsideClickDetector>
        <div id="swt-main" className={this.state.showNavMenu ? "swt-main" : "swt-main-no-nav"}>
          <Switch>
            {/* Open Routes */}
            <Route path="/authenticate" exact render={(props) => (<CredentialInput {...props} passCredentials={this.passCredentials} resetPassword={this.resetPassword} />)} />
            <Route path="/pw-reset" render={(props) => (<PasswordReset {...props} handleResetPassword={this.handleResetPassword} />)} />
            <Route path="/pw-reset-msg" render={(props) => <PasswordResetMsg />} />
            <Route path="/pw-change" render={(props) => (<PasswordChange {...props} passwordRules={this.secrets.passwordRules} cancelRequest={this.handleChangePasswordCancel} handleRequest={this.handlePasswordChange} />)} />
            <Route path="/token-change" render={(props) => (<PasswordTokenChange {...props} passwordRules={this.secrets.passwordRules} cancelRequest={this.handleTokenChangePasswordCancel} handleRequest={this.handleTokenPasswordChange} />)} />
            <Route path="/handle-expired-token" render={(props) => (<HandleExpiredToken handleResetPassword={this.handleResetPassword} />)} />
            {/* Private Routes */}
            <PrivateRoute path="/landing" component={LandingComponent} 
              dbDisplayName={this.getDbDisplayName()} 
              onSelect={this.handleNavItemSelect}
              products={this.state.products}
              tcoSavings={this.state.tcoSavings}
              vehiclesAnalyzed={this.state.vehiclesAnalyzed}
              currencySymbol={this.state.user.userSettings.currency_symbol}
              auth={authenticated}
            />
            {
              this.ComponentRegistry.register.map(c => {
                return (<PrivateRoute path={c.path} key={c.name} component={this.ComponentRegistry[c.name]}
                  devState={this.state.devState}
                  secrets={this.secrets}
                  auth={authenticated}
                  basename={this.basename}
                  db={this.state.db}
                  dbDisplayName={this.getDbDisplayName()}
                  dbSettings={this.state.productSettings}
                  dbName={this.state.db}
                  apiURL={this.state.apiURL}
                  user={this.state.user}
                  products={this.state.products} />)
              })
            }
            <PrivateRoute path='/user-settings' component={UserSettings} auth={authenticated} updateUserSettings={updateUserSettings} db={this.state.db} dbDisplayName={this.getDbDisplayName()} user={this.state.user} apiURL={this.state.apiURL} dbSettings={this.state.dbSettings}/>

            {/* Admin Routes */}
            <AdminRoute path="/admin" component={AdminMain}
              posthog={this.state.posthog}
              auth={authenticated}
              db={this.state.db}
              dbDisplayName={this.getDbDisplayName()}
              dbSettings={this.state.dbSettings}
              user={this.state.user}
              apiURL={this.state.apiURL}
              addDatabase={this.addDatabase}
              createNewUserPassword={this.createNewUserPassword}
              passwordRules={this.state.passwordRules}
              updateDbSettings={updateDbSettings}
              changeDbCobrandingLogo={this.changeDbCobrandingLogo}
              hasDbCobrandingUrl={this.state.cobrandingLogoUrl ? true : false}
              redirect={this.handleNavItemSelect}
              dbUiUpdating={this.state.dbUiUpdating}
            />
            <SuperAdminRoute path="/cdr-admin" component={CDRManagementMain} user={this.state.user} db={this.state.db} apiURL={this.state.apiURL} createNewUserPassword={this.createNewUserPassword} passwordRules={this.state.passwordRules} dbSettings={this.state.dbSettings} updateDatabases={this.updateDatabases} getDbUiUpdateStatus={this.getDbUiUpdateStatus}/>
            <SuperAdminRoute path="/dev-tools" component={DevToolsMain}  
              devState={this.state.devState}
              secrets={this.secrets}
              auth={authenticated}
              basename={this.basename}
              db={this.state.db}
              dbDisplayName={this.getDbDisplayName()}
              dbSettings={this.state.productSettings}
              dbName={this.state.db}
              apiURL={this.state.apiURL}
              user={this.state.user}
            />
            <SuperAdminRoute path="/location-edit-tool" component={LocationEditTool}  
              devState={this.state.devState}
              secrets={this.secrets}
              auth={authenticated}
              basename={this.basename}
              db={this.state.db}
              dbDisplayName={this.getDbDisplayName()}
              dbSettings={this.state.productSettings}
              dbName={this.state.db}
              apiURL={this.state.apiURL}
              user={this.state.user}
            />
          </Switch>
          <ContactCta></ContactCta>
        </div>
      </div>
    );
  }
}

export default withRouter(App);

const PrivateRoute = ({ component: Component, basename, db, dbName, dbDisplayName, dbSettings, user, devState, secrets, apiURL, dashType, auth, updateUserSettings, products, ...rest }) => {
  return (<Route push {...rest} render={(props) => {
    if (auth === true) return <Component {...props} {...rest} updateUserSettings={updateUserSettings} basename={basename} dbSettings={dbSettings} db={db} dbName={dbName} dbDisplayName={dbDisplayName} apiURL={apiURL} secrets={secrets} devState={devState} user={user} dashType={dashType} products={products} />
    else return <Redirect to="/" />
  }}
  />
  );
};

const AdminRoute = ({
  component: Component, posthog, db, dbDisplayName, dbSettings, user, devState, secrets, apiURL, addDatabase, createNewUserPassword, passwordRules, auth, updateDbSettings, redirect, ...rest }) => {
  return (
    <Route exact {...rest} render={(props) => {
      if (user.role >= Roles.FleetAdmin) { return (<Component {...props} {...rest} posthog={posthog} updateDbSettings={updateDbSettings} db={db} dbDisplayName={dbDisplayName} dbSettings={dbSettings} apiURL={apiURL} secrets={secrets} devState={devState} user={user} createNewUserPassword={createNewUserPassword} passwordRules={passwordRules} addDatabase={addDatabase} redirect={redirect} />) }
      else return <Redirect to="/landing" />
    }
    }
    />
  );
};

const SuperAdminRoute = ({ component: Component, user, passwordRules, ...rest }) => {
  return(
    <Route exact {...rest} render={(props) => {
      if(user.role >= Roles.SuperAdmin)return (<Component user={user} passwordRules={passwordRules} {...rest} {...props} />);
      else return <Redirect to="/landing" />
    }
    }
    />
  );
};