import React, { useState, useEffect, useReducer, useMemo, Suspense, useRef } from "react"
import { ChargeLocation } from "../swt-ezio";
import * as S from "../../styles/ezio-styles/LocationSummary-styles"
//import StatBox from "./StatBox"
import Table from "./Table"
import { columnsToExcelHeaders, rowsToData } from "../../utils/ezio-utils/ExcelUtils"
import { vehicleIdDisplay, locationPageTableColumns, formatChargingStatus } from "./TableHelpers";
import GraphMonthly from "./GraphMonthly";
import GraphMonthlyTod from "./GraphMonthlyTod";
import { StatView } from "@sawatchlabs/swt-components";
import { DateTime } from "luxon";
import { PdfSingleLocation } from "./PdfSingleLocation";
import { usePDF } from '@react-pdf/renderer';
import { formatTimestamp, sanitizeDownloadString } from "../../utils/ezio-utils/ConformUnits";
import { useParams } from "react-router-dom";
import { filterByCategory, filterByGroups, filterByVehicleClass } from "../../utils/ezio-utils/DataUtils";

//code-splitting imports
const ExcelDownloadButton = React.lazy(() => import("./ExcelDownloadButton"));

export type LocationSummaryProps = {
    chargeLocations: Map<string, ChargeLocation>
    selectedChargeLocation: string
    req: any
    totalVehicleCount: number
    category?: string
    vehicleClasses?: Array<string>
    groups?: Array<any>
    electrification?: number
    beginDate?: DateTime
    endDate?: DateTime
    dbDisplayName: string,
    userSettings: any,
    forceNavigate: Function,
    ALL_LOCATIONS_ID: string
}

type StatBoxesState = {
    vehiclesProjectedToCharge: {
        value: string,
        subValue: string,
        caption?: string
    },
    vehiclesWithPassingEvScore: {
        value: string
        caption?: string
    },
    highestPeakDemand: {
        value: string,
        subValue: string,
        caption?: string
    },
    level2PortsNeeded: {
        value: string
        caption?: string
        subCaption?: string
    },
    dcfcPortsNeeded: {
        value: string
        caption?: string
        subCaption?: string
    },
    totalLocationCharging: {
        value: string
        subValue?: string
        caption?: string
    }
}

type Params = {
    urlId: string
}

export default function LocationSummary({ chargeLocations, selectedChargeLocation, req, totalVehicleCount, category, groups, vehicleClasses, beginDate, endDate, electrification, dbDisplayName, userSettings, forceNavigate, ALL_LOCATIONS_ID }: LocationSummaryProps) {
    const [showVehicleCountToolTip, setShowVehicleCountToolTip] = useState<Boolean>(false);
    const [showPrimaryParkingToolTip, setShowPrimaryParkingToolTip] = useState<Boolean>(false);
    const [showParkingPercentageToolTip, setShowParkingPercentageToolTip] = useState<Boolean>(false);
    const chargeLocation: any = chargeLocations.get(selectedChargeLocation);
    const [cursorCoords, setCursorCoords] = useState({ x: 0, y: 0 });

    const [pdfLoaded, setPdfLoaded] = useState(false);

    const monthlyDemandGraphRef = useRef(null);
    const [monthlyDemandBase64, setMonthlyDemandBase64] = useState(null);
    const [monthlyGraphLoaded, setMonthlyGraphLoaded] = useState(false);

    const dailyDemandGraphRef = useRef(null);
    const [dailyDemandBase64, setDailyDemandBase64] = useState(null);
    const [dailyGraphLoaded, setDailyGraphLoaded] = useState(false);

    const { urlId } = useParams<Params>();

    // Check if the location summary page has been navigated to with the all locations ID (ie. via a refresh or browser history navigation). If so, force a controls reset with the location UUID provided by the URL. forceNavigate will handle if the URL is invalid (though that could only happen with a manual URL entry, which can currently only happen when running locally).
    useEffect(() => {
        if(selectedChargeLocation === ALL_LOCATIONS_ID) {
            console.error("Location summary page was loaded with 'All Locations' selected. Attempting to reload with the location ID from the URL.")
            forceNavigate(urlId);
        }
    // This check should only run on initial component render, since it's impossible for users to select 'All Locations' once inside this page, so leaving the dependency array empty intentionally.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if (monthlyGraphLoaded && dailyGraphLoaded) {
            if (dailyDemandGraphRef.current) setDailyDemandBase64(dailyDemandGraphRef.current.toBase64Image());
            if (monthlyDemandGraphRef.current) setMonthlyDemandBase64(monthlyDemandGraphRef.current.toBase64Image());
            setPdfLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthlyGraphLoaded, dailyGraphLoaded]);

    const handleShowPrimaryParkingToolTip = (show: Boolean, mouseEvent: any) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        setShowPrimaryParkingToolTip(show);
    }
    const handleShowVehicleCountToolTip = (show: Boolean, mouseEvent: any) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        setShowVehicleCountToolTip(show);
    }
    const handleShowParkingPercentageToolTip = (show: Boolean, mouseEvent: any) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        setShowParkingPercentageToolTip(show);
    }

    const excelDataSort = (a: any, b: any) => {
        // [4] = avg charge hours
        // [9] = homebase

        // Sort by Charging/Not Charging first
        if(formatChargingStatus(a[4]) < formatChargingStatus(b[4])) return 1;
        if(formatChargingStatus(a[4]) > formatChargingStatus(b[4])) return -1;

        // Secondary sort by homebase value
        if(a[9] < b[9]) return -1;
        if(a[9] > b[9]) return 1;

        // Tertiary charge values sort
        return a[4] < b[4] ? 1 : -1;
    }
    
    const obj = locationPageTableColumns.find((vtc) => vtc.Header === "Primary Parking Location");
    obj.showToolTip = handleShowPrimaryParkingToolTip;

    const [statBoxesState, dispatchStatBoxes] = useReducer((state: StatBoxesState, action: Partial<StatBoxesState>) => ({
        ...state,
        ...action

    }),
        {
            vehiclesProjectedToCharge: {
                value: "",
                subValue: ""
            },
            vehiclesWithPassingEvScore: {
                value: ""
            },
            highestPeakDemand: {
                value: "",
                subValue: "kW"
            },
            level2PortsNeeded: {
                value: ""
            },
            dcfcPortsNeeded: {
                value: ""
            },
            totalLocationCharging: {
                value: "",
                subValue: "%"
            }
        }
    );
    
    const generateDocument = () => 
        _pdfReport(
            dbDisplayName,
            chargeLocations.get(selectedChargeLocation).nickname ?? chargeLocations.get(selectedChargeLocation).address,
            category,
            groups,
            vehicleClasses,
            `${electrification}%`,
            formatTimestamp(userSettings, beginDate).date,
            formatTimestamp(userSettings, endDate).date,
            statBoxesState,
            monthlyDemandBase64,
            dailyDemandBase64
        );


    const [instance, updateInstance] = usePDF({document: generateDocument()});

    const DownloadPdfButton = () => {
        const date = formatTimestamp(userSettings, DateTime.local()).date;
        const downloadName = sanitizeDownloadString(`ezio-location-report-${dbDisplayName}-${chargeLocations.get(selectedChargeLocation).nickname ?? chargeLocations.get(selectedChargeLocation).address}-${date}`);
        return(
        <S.PdfDownload href={instance.url} download={downloadName} disabled={!pdfLoaded} id="download-pdf-button-testid">
          Download PDF
        </S.PdfDownload>
        );
      }

      useEffect(() => {
        updateInstance(generateDocument());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statBoxesState,dailyDemandBase64,monthlyDemandBase64,chargeLocation,endDate,beginDate,groups,vehicleClasses,electrification]);

    useEffect(() => {
        dispatchStatBoxes({
            vehiclesProjectedToCharge: {
                value: `${chargeLocation?.inBoundChargingVehiclesCount ? chargeLocation.inBoundChargingVehiclesCount : 0}`,
                subValue: `/${totalVehicleCount ? totalVehicleCount : 0}`,
                caption: "Vehicles Projected to Charge/Total Vehicles in Fleet"
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chargeLocation])

    useEffect(() => {
        dispatchStatBoxes({
            vehiclesWithPassingEvScore: {
                value: `${chargeLocation?.evRecommendationCount ? chargeLocation.evRecommendationCount : 0}`,
                caption: "Vehicles Recommended for Replacement with an EV"

            }
        })
    }, [chargeLocation])

    useEffect(() => {
        dispatchStatBoxes({
            highestPeakDemand: {
                value: `${chargeLocation?.peakKw ? chargeLocation.peakKw : 0}`,
                subValue: "kW",
                caption: "Highest Peak Demand at Same Time"
            }
        })

    }, [chargeLocation])

    useEffect(() => {
        const locationHistogram = chargeLocation.drawHistogram
        dispatchStatBoxes({
            level2PortsNeeded: {
              value: `${locationHistogram[0]}/${locationHistogram[1]}/${locationHistogram[2]}`,
              caption: `L2 Ports Needed`,
              subCaption: '(7kW/11kW/20kW)'
            }
          })
          dispatchStatBoxes({
            dcfcPortsNeeded: {
                value: `${locationHistogram[3]}/${locationHistogram[4]}`,
                caption: 'DCFC Ports Needed',
                subCaption: '(50kW/100kW)'
            }
          })
    }, [chargeLocation])

    useEffect(() => {
        const locationCharging =  chargeLocation.atLocationChargingKwh ? chargeLocation.atLocationChargingKwh : 0;
        const nonLocationCharging =  chargeLocation.notAtLocationChargingKwh ? chargeLocation.notAtLocationChargingKwh : 0;
        const totalCharging = locationCharging+nonLocationCharging;
        const val = totalCharging > 0 ? Math.round(locationCharging / totalCharging * 100) : 0 ; 
        // const val = 0;
        dispatchStatBoxes({
            totalLocationCharging: {
                value: `${val}`,
                subValue: "%",
                caption: "Charging Projected at This Location"
            }
        })
    }, [chargeLocation]);

    useMemo(() => {
        function dateSort(a, b) {
            if(a.local_start < b.local_start)return 1;
            if(a.local_start > b.local_start)return -1;
            return 0;
        }
        if(chargeLocation && chargeLocation.chargingVehiclesVins && chargeLocation.charge_events){
            chargeLocation.chargingVehiclesVins.forEach((v: string) => {
                const vcl = chargeLocation.vehicleResults.find((b: any) => b.vin === v);
                const ces = chargeLocation.charge_events.filter((ce: any) => ce?.vin === v);
                ces.sort(dateSort);
                let duration = 0;
                let count = 1;
                let bm = ces[0]?.local_start.split(' ')[0]
                let date_kwh_totals = new Map(); // hash of the kwh totals for each date
                ces.forEach((ce: any)=>{
                    if(bm !== ce.local_start.split(' ')[0]){
                        count++; //counting distinct dates
                        bm = ce.local_start.split(' ')[0];
                    }
                    const parsable_start = ce.local_start.replace(" ", "T");
                    const parsable_stop = ce.local_stop.replace(" ", "T");
                    const ts0 = DateTime.fromISO(parsable_start).toUTC();
                    const ts1 = DateTime.fromISO(parsable_stop).toUTC();
                    duration += (ts1.ts - ts0.ts)/1000/3600;

                    if (date_kwh_totals.has(bm)) { // add the event's kwh to the total for the day if it's already in the map
                        date_kwh_totals.set(bm, date_kwh_totals.get(bm) + ce.kwh);
                    } else {
                        date_kwh_totals.set(bm, ce.kwh);
                    }
                });
                
                let values = Array.from(date_kwh_totals.values());
                let sum = values.reduce((acc, val) => acc + val, 0);
                vcl.loc_max_daily_kwh = values.reduce((acc, val) => Math.max(acc, val), 0);
                vcl.loc_avg_daily_kwh = sum / values.length;
                
                vcl.averageChargeHours = duration/count;
            })
        }
    }, [chargeLocation]);

    const formattedData = useMemo(() => {
        if(!chargeLocation) {return null}
        let data = JSON.parse(JSON.stringify(chargeLocation.vehicleResults))
        // Filter vehicle data based on controls
        data = filterByCategory(data, category);
        data = filterByVehicleClass(data, vehicleClasses);
        data = filterByGroups(data, groups);

        return data.map((vcl) => {
            vcl.asset_id = vehicleIdDisplay(vcl)
            return vcl;
        })
    },[chargeLocation, category, groups, vehicleClasses])

    return (
        <>
            < S.PageLayout showTableRow={chargeLocation.vehicleResults.length > 0}>
                <S.ContentWrapper>
                <S.HeaderWrapper>
                    <div>
                        <S.PageHeader>Location Summary</S.PageHeader>
                        <S.LocationPageAddress id="location-address-testid">{chargeLocations.get(selectedChargeLocation).nickname ?? chargeLocations.get(selectedChargeLocation).address}</S.LocationPageAddress>
                    </div>
                    <DownloadPdfButton/>
                </S.HeaderWrapper>

                <S.StatBoxRow>
                    <S.StatBoxContainer >
                        {/* evRecommendationCount / vehicleCount of location  */}
                        {/* evRecommendationCount / vehicleCount of location  */}
                        {showVehicleCountToolTip &&
                        <S.ToolTipWrapper cursorCoords={cursorCoords}
                            onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
                            onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
                        >
                        <S.ToolTipText>
                        This is the number of vehicles projected to charge at this location based on the selected filters, compared to the total number of vehicles in the fleet. Vehicles may be included in these figures that currently do not use this location as their primary parking location.
                        </S.ToolTipText>
                        </S.ToolTipWrapper>
                        }
                        {chargeLocation &&
                            <StatView
                                valueId={"projected-to-charge-testid"}
                                subValueId={"projected-to-charge-total-testid"}
                                captionId={"projected-to-charge-caption-testid"}
                                values={statBoxesState.vehiclesProjectedToCharge}
                                onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
                                onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
                            />
                        }
                    </S.StatBoxContainer>
                    <S.StatBoxContainer >
                        {/* evRecommendationCount / vehicleCount of location  */}
                        <StatView
                            valueId={"recommended-vehicle-count-testid"}
                            captionId={"recommended-vehicle-caption-testid"}
                            values={statBoxesState.vehiclesWithPassingEvScore}
                        />
                    </S.StatBoxContainer>
                    <S.StatBoxContainer>
                        <StatView
                            valueId={"highest-peak-demand-testid"}
                            subValueId={"highest-peak-demand-subvalue-testid"}
                            captionId={"highest-peak-demand-caption-testid"}
                            values={statBoxesState.highestPeakDemand}
                        />
                    </S.StatBoxContainer>
                    {/* Same as max EVs for now */}
                    <S.StatBoxContainer>
                        <StatView
                            valueId={"l2-port-count-testid"}
                            captionId={"l2-port-count-caption-testid"}
                            subCaptionId={"l2-port-count-subcaption-testid"}
                            values={statBoxesState.level2PortsNeeded}
                        />
                    </S.StatBoxContainer>
                    <S.StatBoxContainer>
                        <StatView
                            valueId={"dcfc-port-count-testid"}
                            captionId={"dcfc-port-count-caption-testid"}
                            subCaptionId={"dcfc-port-count-subcaption-testid"}
                            values={statBoxesState.dcfcPortsNeeded}
                        />
                    </S.StatBoxContainer>
                    <S.StatBoxContainer
                        onMouseEnter={(e) => {handleShowParkingPercentageToolTip(true, e)}}
                        onMouseLeave={(e) => {handleShowParkingPercentageToolTip(false, e)}}
                    >
                        {showParkingPercentageToolTip &&
                            <S.ParkingToolTipWrapper cursorCoords={cursorCoords}
                                onMouseEnter={(e) => {handleShowParkingPercentageToolTip(true, e)}}
                                onMouseLeave={(e) => {handleShowParkingPercentageToolTip(false, e)}}
                            >
                            <S.ToolTipText>
                                For the vehicles that park at this location, this is the percentage of their charging that will occur at this location. If this is below 100%, some of the vehicles are projected to charge at other locations, too.
                            </S.ToolTipText>
                            </S.ParkingToolTipWrapper>
                        }
                        <StatView
                            valueId={"charging-percentage-testid"}
                            captionId={"charging-percentage-caption-testid"}
                            values={statBoxesState.totalLocationCharging}
                            onMouseEnter={(e) => {handleShowParkingPercentageToolTip(true, e)}}
                            onMouseLeave={(e) => {handleShowParkingPercentageToolTip(false, e)}}
                        />
                    </S.StatBoxContainer>
                </S.StatBoxRow>
                </S.ContentWrapper>
                {chargeLocation?.monthlyPeaks &&
                    <> 
                        <S.GraphContainer>
                            <S.GraphContainerLhs>
                                <GraphMonthly
                                    graphRef={monthlyDemandGraphRef}
                                    monthlyPeaks={chargeLocation.monthlyPeaks}
                                    displayTitle={true}
                                    setGraphLoaded={setMonthlyGraphLoaded}
                                />
                            </S.GraphContainerLhs>
                            <S.GraphContainerRhs>
                                < GraphMonthlyTod 
                                    graphRef={dailyDemandGraphRef}
                                    monthlyPeaks={chargeLocation.monthlyPeaks} 
                                    userSettings={userSettings}
                                    setGraphLoaded={setDailyGraphLoaded}
                                 />
                            </S.GraphContainerRhs>
                        </S.GraphContainer>
                    </>}
                <S.TableContainer>
                    <S.TableTitle>Vehicles</S.TableTitle>
                    <S.TableSubtitle>
                        This table lists the vehicles that are projected to charge at this location.
                    </S.TableSubtitle>
                    <div style={{position: 'relative'}}>
                    {showPrimaryParkingToolTip &&
                        <S.TableParkingToolTipWrapper cursorCoords={cursorCoords}
                            onMouseEnter={(e) => {handleShowPrimaryParkingToolTip(true, e)}}
                            onMouseLeave={(e) => {handleShowPrimaryParkingToolTip(false, e)}}
                        >
                        <S.ToolTipText>
                        This is the current primary parking location of this vehicle based on our analysis of most recent 30 days of travel.
                        </S.ToolTipText>
                         </S.TableParkingToolTipWrapper>
                    }
                    <Table
                        columns={locationPageTableColumns}
                        data={formattedData}
                        hoverHighlight={false}
                        defaultPrimarySort={{columnId:"willChargeWithSelectedFilters",sortDesc:true}}
                        defaultSecondarySort={{columnId:"primaryParkingLocation",sortDesc:true}}
                        defaultTertiarySort={{columnId:"chargingHours",sortDesc:true}}
                    />
                    </div>
                    <div>
                        <Suspense fallback={<div></div>}>
                            <ExcelDownloadButton
                                csvType={'vehicles'}
                                beginDate={beginDate}
                                endDate={endDate}
                                category={category}
                                groups={groups}
                                vehicleClasses={vehicleClasses}
                                electrification={electrification}
                                location={chargeLocation}
                                db={req.dbName}
                                columns={columnsToExcelHeaders(locationPageTableColumns)}
                                data={rowsToData(locationPageTableColumns, formattedData, excelDataSort)}
                                dbDisplayName={dbDisplayName}
                                userSettings={userSettings}
                            />
                        </Suspense>
                    </div>

                </S.TableContainer>
            </S.PageLayout>
        </>
    )
}

function _pdfReport(
  dbDisplayName: string,
  locAddress: string,
  selectedCategory: string,
  groups: Array<object>,
  vehicleClasses: Array<string>,
  electrificationPercent: string,
  startDate: string,
  endDate: string,
  statBoxList: object,
  monthlyDemandBase64: any,
  dailyDemandBase64: any,
) {
  return (
    <PdfSingleLocation
      dbDisplayName={dbDisplayName}
      locAddress={locAddress}
      selectedCategory={selectedCategory}
      groups={groups}
      vehicleClasses={vehicleClasses}
      electrificationPercent={electrificationPercent}
      startDate={startDate}
      endDate={endDate}
      statBoxList={statBoxList}
      dailyDemandBase64={dailyDemandBase64}
      monthlyDemandBase64={monthlyDemandBase64}
    />
  );
}